import React, { Component } from 'react';
import { Link, Redirect, Route , withRouter } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ViewCartSkeleton from '../skeleton/view_cart_skeleton';
import {VIEW_CART_API_URL, SHOP_DELIVERED_OR_NOT_API_URL,REMOVE_ADDRESS_FROM_SAVE, ORDER_PLACE_URL, B2BSOCKET_URL, CUSTMER_VERIFY} from '../Comman/Constant';

import 'icheck/skins/all.css';
import {Radio} from 'react-icheck';


class CartSummeryWidegt extends Component {

    constructor(props){
        super(props);
        const schedule_slot = localStorage.getItem('schedule_slot');
        const from = localStorage.getItem('from');
        const to = localStorage.getItem('to');
        const schedule_order = localStorage.getItem('schedule_order');
        const delivery_type = localStorage.getItem('delivery_type') ? localStorage.getItem('delivery_type') : "schedule";
        const customerLocation = localStorage.getItem('customerLocation') ? localStorage.getItem('customerLocation') : "";

        this.state = {
            locationHistory: this.props.location,
            cart_products: [],
            addon_products: [],
            isCartLoading: false,
            delivery_type : delivery_type,
            schedule_slot : schedule_slot ? schedule_slot : "",
            from : from ? from : "",
            to : to ? to : "",
            schedule_order : schedule_order ? schedule_order : "",
            error_message: "",
            scheduleSlotData: [],
            payment_method: this.props.payment_method,
            payment_method_label: this.props.payment_method_label,
            card_id: this.props.card_id,
            selectedCreditDate: this.props.selectedCreditDate,
            selectedDays:this.props.selectedDays,
            customerLocation: customerLocation,
            customerLocationList: [],
            customerSaveLocationList: [],
            price_show: "no"
        }
        this.deliveryType = this.deliveryType.bind(this);
        this.schedulePopup = this.schedulePopup.bind(this);
        this.saveSlotData = this.saveSlotData.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.qtyMinus = this.qtyMinus.bind(this);
        this.qtyPlus = this.qtyPlus.bind(this);

    }

    componentDidMount(){
        const device_id = localStorage.getItem('device_id');
        if(!device_id){
            return <Redirect to='/your_location'/>
        }

        const requestParam = {
            language_id: 1,
            device_id: device_id,
            user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            card_id: this.state.card_id,
            selectedDate:this.props.selectedDate,
            cart_action:this.props.cart_action
        }
        this.fetchData(requestParam);
    }

    fetchData = async (requestParam) => {

        this.setState({
            isCartLoading: true,
        });
        var that = this;

        if(this.state.locationHistory.pathname == '/order_step_one'){
            const schedule_date = localStorage.getItem('schedule_date');
            requestParam.selectedDays = schedule_date;
        }

        await axios.post(VIEW_CART_API_URL, requestParam,
        {
            headers: {
            'Authorization': requestParam.device_id
            }
        })
        .then((response) => {
            if(response.data.is_schedule_order == 'yes'){
                var dt = 'schedule';
            } else {
                var dt = localStorage.getItem('delivery_type') ? localStorage.getItem('delivery_type') : "now";
            }
            this.setState({
            cart_products: response.data.cart_products ? response.data.cart_products : [],
            addon_products: response.data.addon_products ? response.data.addon_products : [],
            scheduleSlotData: response.data.scheduleSlotData ? response.data.scheduleSlotData : [],
            coupon_discount: response.data.coupon_discount,
            delivery_cost: response.data.delivery_cost ? response.data.delivery_cost : "0.00",
            actual_delivery_cost: response.data.actual_delivery_cost ? response.data.actual_delivery_cost : "0.00",
            delivery_discount: response.data.delivery_discount,
            final_amount: response.data.final_amount,
            subtotal: response.data.subtotal,
            orderWiseDelivery: response.data.orderWiseDelivery,
            city_id: response.data.city_id,
            shop_id: response.data.shop_id,
            free_delivery: response.data.free_delivery,
            free_delivery_by: response.data.free_delivery_by,
            is_schedule_order: response.data.is_schedule_order,
            delivery_type: response.data.is_schedule_order == 'yes' ? 'schedule' : dt,
            is_schedule_on: response.data.is_schedule_on,
            is_now_on: response.data.is_now_on,
            preparation_text: response.data.preparation_text,
            isCartLoading: false,
            error_message: response.data.error_message ? response.data.error_message : "",
            selectedCartData: response.data.selectedCartData ? response.data.selectedCartData : [],
            customerLocationList: response.data.customerLocationList ? response.data.customerLocationList : [],
            customerSaveLocationList: response.data.customerSaveLocationList ? response.data.customerSaveLocationList : [],
            customerSaveLocationListTemp: response.data.customerSaveLocationList ? response.data.customerSaveLocationList : [],
            payment_method: response.data.default_method ? response.data.default_method : this.props.payment_method,
            payment_method_label: response.data.default_method_label ? response.data.default_method_label : this.props.payment_method_label,
            selectedCreditDate: response.data.default_method_date ? response.data.default_method_date : this.props.selectedCreditDate,
            price_show: response.data.price_show,
            allow_address: response.data.allow_address,
            is_charged_show: response.data.is_charged_show,

            });
            localStorage.setItem('delivery_type',dt);
            localStorage.setItem('shop_id',this.state.shop_id);
            localStorage.setItem('is_charged_show', response.data.is_charged_show);

            if(response.data.popup_alert){
                confirmAlert({
                    title: '',
                    message: response.data.popup_alert,
                    buttons: [
                        {
                        label: 'Okay',
                        },

                    ]
                });
            }


        }).catch(function (error) {
            if(error.response.status==401){
                    that.props.history.push('/sign-in');
            }
        });;

        if(requestParam.reRanderParent){
            this.state.locationHistory.pathname != "/order_step_one" &&
            this.props.rerenderParentCallback();
        }
        this.props.rerenderParentCallback();
    }

    removeItem =(event) => {
        event.preventDefault();
        const device_id = localStorage.getItem('device_id');
        if(!device_id){
            return <Redirect to='/your_location'/>
        }

        let cart_table_id = event.currentTarget.getAttribute('data-jjdiejereixw');
        let product_code = event.currentTarget.getAttribute('data-hfgkkeirn4df');

        const requestParam = {
            language_id: 1,
            device_id: device_id,
            user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            cart_table_id: cart_table_id,
            product_code: product_code,
            update_cart: 'delete_item',
            card_id: this.state.card_id,
            reRanderParent: true
        }

        this.fetchData(requestParam);
    }

    qtyPlus =(event) => {
        event.preventDefault();
        const device_id = localStorage.getItem('device_id');
        if(!device_id){
            return <Redirect to='/your_location'/>
        }

        let cart_table_id = event.currentTarget.getAttribute('data-jjdiejereixw');
        let product_qty = event.currentTarget.getAttribute('data-qtjdrjnvrnkd');

        product_qty = parseFloat(product_qty) + parseFloat(1);

        const requestParam = {
            language_id: 1,
            device_id: device_id,
            user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            cart_table_id: cart_table_id,
            quantity_to_update: product_qty,
            update_cart: 'update_qty',
            card_id: this.state.card_id
        }

        this.fetchData(requestParam);

    }

    qtyChange =(event) => {
        event.preventDefault();
        const device_id = localStorage.getItem('device_id');
        if(!device_id){
            return <Redirect to='/sign-in'/>
        }

        let cart_table_id = event.currentTarget.getAttribute('data-jjdiejereixw');
        var product_qty = event.target.value.replace(/[^0-9\.]/g, '');

        if(product_qty <= 0){
              return false;
        }

        const requestParam = {
            language_id: 1,
            device_id: device_id,
            user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            cart_table_id: cart_table_id,
            quantity_to_update: product_qty,
            update_cart: 'update_qty',
            card_id: this.state.card_id
        }

        this.fetchData(requestParam);

    }

    qtyMinus =(event) => {
        event.preventDefault();
        const device_id = localStorage.getItem('device_id');
        if(!device_id){
            return <Redirect to='/your_location'/>
        }

        let cart_table_id = event.currentTarget.getAttribute('data-jjdiejereixw');
        let product_qty = event.currentTarget.getAttribute('data-qtjdrjnvrnkd');

        product_qty = parseFloat(product_qty) - parseFloat(1);
        if(product_qty <= 0){
            return false;
        }

        const requestParam = {
            language_id: 1,
            device_id: device_id,
            user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            cart_table_id: cart_table_id,
            quantity_to_update: product_qty,
            update_cart: 'update_qty',
            card_id: this.state.card_id
        }

        this.fetchData(requestParam);

    }

    removeAddress = (event,address_id,customer_lat,customer_lng) => {
        event.preventDefault();
        const device_id = localStorage.getItem('device_id');
        const requestParam = {
            language_id: 1,
            device_id: device_id,
            user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
            user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
            update_cart: 'remove_address',
            address_id: address_id,
            address_lat: customer_lat,
            address_lng: customer_lng,
        }

        this.fetchData(requestParam);
    }

    deliveryType = (event) => {
        const target = event.currentTarget;
        var value = target.value;

        if(value == 'schedule'){
            //this.schedulePopup();
        } else {
            localStorage.removeItem('schedule_order');
            localStorage.removeItem('schedule_slot');
            localStorage.removeItem('schedule_slot_date');
            localStorage.removeItem('delivery_type');

        }
        localStorage.setItem('delivery_type',value);
        this.setState({
            delivery_type : value,
        });
        this.props.rerenderParentCallback();

    }

    saveSlotData = (event) => {
        const target = event.currentTarget;
        var value = target.value;
        var slot_date = event.target.dataset.slot_date;
        localStorage.setItem('schedule_order','yes');
        localStorage.setItem('schedule_slot', value);
        localStorage.setItem('schedule_slot_date', slot_date);
        localStorage.setItem('delivery_type','schedule');
        this.schedulePopupCloseBtn.click();
        this.setState({
            schedule_order : 'yes',
            schedule_slot : value,
            schedule_slot_date : slot_date,
            delivery_type: 'schedule'
        });

    }

    schedulePopup = () => {
        confirmAlert({

            customUI: ({ onClose }) => {
            return (
                <div className='react-confirm-alert-body schedule_slot_popup'>
                    <div className="row">
                        <div className="col-lg-4 text-center" style={{backgroundColor: "#0D882B",color: "#fff",padding:'5px',borderRadius:"5px"}}>
                            Slot availabel
                        </div>
                        <div className="col-lg-4 text-center" style={{backgroundColor: "#F6DB4A",color: "#fff",padding:'5px',borderRadius:"5px"}}>
                            Filling Fast
                        </div>
                        <div className="col-lg-4 text-center" style={{backgroundColor: "#F62B00",color: "#fff",padding:'5px',borderRadius:"5px"}}>
                            1 to 3 slots
                        </div>
                    </div>
                    <p className="text-danger">
                    {
                        Object.keys(this.state.scheduleSlotData).length !== 0 ?
                            this.state.scheduleSlotData.map( (scheduleData,scheduleKey) => {

                                return(
                                    <div className="schedule_container" key={scheduleKey}>
                                        <div className="slotTitle text-center">{ scheduleData.day } - {scheduleData.date}</div>
                                        <div className="row">
                                            {
                                                scheduleData.time.map( (slotData,slotKey) => {

                                                    return(
                                                        <div key={slotKey} className="col-lg-2 slot_back">
                                                            <input className="slot_btn" onClick={this.saveSlotData} type="button" data-slot_date={scheduleData.date} value={slotData.slot} style={{ backgroundColor: slotData.left_slot <= 3 ? "#F62B00" : slotData.left_slot >= 4 && slotData.left_slot <= 6 ? "#F6DB4A" : "#0D882B" }} />

                                                        </div>
                                                    );

                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }) : null
                    }
                    </p>
                    <div className="react-confirm-alert-button-group schedule_popup_close_btn">
                        <button className="schedule_popup_close_btn" onClick={onClose} ref={input => this.schedulePopupCloseBtn = input}>Cancel</button>

                    </div>
                </div>
            );
            }
        });
    }

    orderNowBtn = (event) => {
        event.preventDefault();

        const device_id = localStorage.getItem('device_id');
        const customer_mobile = localStorage.getItem('customer_mobile');
        if(!customer_mobile){
            this.props.history.push('/sign-in?redirect_to=1');
            return false;
        }
        if(!device_id){
            this.props.history.push('/sign-in?redirect_to=1');
            this.props.history.push('/your_location/');
            return false;
        }
        this.setState({
            isCartLoading: true,
        });

         this.props.history.push('/order_step_one');

    }

    order_palce = () => {

            const device_id = localStorage.getItem('device_id');
            const customer_order_notes = localStorage.getItem('customer_order_notes');
            const customerLocation = localStorage.getItem('customerLocation');
            const customer_mobile = localStorage.getItem('customer_mobile');
            const customer_mobile_no = localStorage.getItem('customer_mobile_no') ? localStorage.getItem('customer_mobile_no') : '';
            const customer_firstname = localStorage.getItem('firstname_order');
            const customer_email = localStorage.getItem('customer_email');

            const customerLng = localStorage.getItem('customerLng');
            const customerLat = localStorage.getItem('customerLat');
            const shop_id = localStorage.getItem('shop_id');

            if(!this.state.customerLocationList.location){
                alert('Location is not available.')
                return false;
            }

            const schedule_order = localStorage.getItem('schedule_order');
            let delivery_type = localStorage.getItem('delivery_type');
            let charged_code = localStorage.getItem('charged_code');

            const selectedDays = this.state.selectedDays;

            if(!delivery_type){
                delivery_type = this.state.delivery_type;
            }

            if(delivery_type == 'schedule' && selectedDays.length == 0 ) {
                confirmAlert({
                        title: '',
                        message:"Please choose delivery date",
                        buttons: [
                          {
                            label: 'Okay',
                          },

                        ]
                      });
                      return false;
                return false;
            }

            if(this.state.is_charged_show == 'yes' && !charged_code ) {
                confirmAlert({
                        title: '',
                        message:"Please enter charged code",
                        buttons: [
                          {
                            label: 'Okay',
                          },

                        ]
                      });
                      return false;
                return false;
            }


            if(delivery_type == 'schedule' && schedule_order != 'yes' ) {
                document.getElementById("saveScheduleTime").focus();
                // confirmAlert({
                //         title: '',
                //         message:"Please choose delivery time",
                //         buttons: [
                //           {
                //             label: 'Okay',
                //           },

                //         ]
                //       });
                //       return false;
                return false;
            }


            if(!customer_mobile){
                this.props.history.push('/sign-in?redirect_to=1');
                  return false;
            }

            if(!this.state.payment_method){
                confirmAlert({
                    title: '',
                    message:"Please select any payment method",
                    buttons: [
                      {
                        label: 'Okay',
                      },

                    ]
                  });
                  return false;
            }

            if(this.state.shop_id == 57 && customer_mobile_no == ''){
                document.getElementById("customer_mobile_no").focus();
                return false;
            }

            if(this.state.shop_id == 79 && customer_mobile_no == ''){
                document.getElementById("customer_mobile_no").focus();
                return false;
            }

            if(this.state.shop_id == 154 && customer_mobile_no == ''){
                document.getElementById("customer_mobile_no").focus();
                return false;
            }

            if(this.state.shop_id == 118 && customer_mobile_no == ''){
                document.getElementById("customer_mobile_no").focus();
                return false;
            }

            if(this.state.shop_id == 124 && customer_mobile_no == ''){
                document.getElementById("customer_mobile_no").focus();
                return false;
            }

            if(this.state.payment_method == 'credit' && this.state.selectedCreditDate == ''){
                 confirmAlert({
                        title: '',
                        message:"Please select credit date",
                        buttons: [
                          {
                            label: 'Okay',
                          },

                        ]
                      });
                      return false;
            }

            if(this.state.payment_method == 'card' || this.state.payment_method == 'mada'){
                if(!this.state.card_id){
                    confirmAlert({
                        title: '',
                        message:"Please choose any card for the payment",
                        buttons: [
                          {
                            label: 'Okay',
                          },

                        ]
                      });
                      return false;
                }
            }

            const schedule_slot = localStorage.getItem('schedule_slot');
            const schedule_slot_date = localStorage.getItem('schedule_slot_date');

            this.setState({
            isCartLoading: true,
            });

            const orderPlaceData = {
                language_id: 1,
                device_id: device_id,
                special_request_note: customer_order_notes,
                product_payment_type: this.state.payment_method,
                card_id: this.state.card_id ? this.state.card_id : "",
                location: customerLocation,
                CityID: this.state.city_id,
                guest_mobile_no: customer_mobile_no ? customer_mobile_no : customer_mobile,
                customer_name: customer_firstname,
                customer_email: customer_email,
                shop_latitude: customerLat,
                shop_longitude: customerLng,
                orderWiseDelivery: this.state.orderWiseDelivery,
                subtotal: this.state.subtotal,
                delivery_cost: this.state.delivery_cost,
                schedule_order: schedule_order ? "yes" : "no",
                selectedDays: selectedDays,
                schedule_slot: schedule_slot,
                selected_credit_date: this.state.selectedCreditDate? this.state.selectedCreditDate : "",
                free_delivery: "",
                free_delivery_by: "",
                charged_code: charged_code
            }

            localStorage.removeItem('schedule_order');
            localStorage.removeItem('schedule_slot');
            localStorage.removeItem('schedule_date');
            localStorage.removeItem('schedule_slot_date');
            localStorage.removeItem('delivery_type');
            localStorage.removeItem('from');
            localStorage.removeItem('to');
            localStorage.removeItem('customer_mobile_no');
            localStorage.removeItem('shop_id');
            localStorage.removeItem('charged_code');

            var that = this;

            axios.post(ORDER_PLACE_URL, orderPlaceData,
            {
                headers: {
                'Authorization': orderPlaceData.device_id
                }
            })
            .then((response) => {
                if(response.data.order_id){

                    localStorage.removeItem('customer_order_notes');

                    const socketData = {
                        device_id:device_id,
                        order_id: response.data.order_id,
                        order_type: schedule_order ? "schedule" : "now",
                        requesttype:"checkout",
                        action:"order_place",
                        device_type:"web",
                        source_type:"b2b",
                        city_id:"9",
                        ask_to_confirm:response.data.ask_to_confirm,
                        sub_admin_id:response.data.sub_admin_id
                    }

                    //that.conn.send(JSON.stringify(socketData));

                    that.props.history.push('/order_place/'+response.data.order_id);
                }
            }).catch(function (error) {
               that.props.history.push('/sign-in');
            });;

    }

    addLocationFromSaveList = (event,addressData) => {
        event.preventDefault();

        axios.post(SHOP_DELIVERED_OR_NOT_API_URL, {
            language_id: 1,
            customerLat: addressData.late,
            customerLng: addressData.lang,
            customerAddress: addressData.address,
            address_note: addressData.address_desc,

            location_name: addressData.location_name,
            location_type: addressData.location_type,
            location_comments: addressData.location_comments,
            location_image: addressData.location_image,
            driver_contact_info: addressData.driver_contact_info,
            customer_contact_info: addressData.customer_contact_info,
            supplied_contact_info: addressData.supplied_contact_info,
            complex_name: addressData.complex_name,
            gate_no: addressData.gate_no,

            device_id: localStorage.getItem('device_id'),
        },{
            headers: {
            'Authorization': localStorage.getItem('device_id')
        }}).then((response) => {
            if(response.data.shop_exits == true){
                const device_id = localStorage.getItem('device_id');
                const requestParam = {
                    language_id: 1,
                    device_id: device_id,
                    user_latitide: localStorage.getItem('customerLat') ? localStorage.getItem('customerLat') : "23.02579",
                    user_longitude: localStorage.getItem('customerLng') ? localStorage.getItem('customerLng') : "72.58727",
                }

                this.fetchData(requestParam);
                this.schedulePopupCloseBtn.click();
            } else {
              confirmAlert({

                customUI: ({ onClose }) => {
                  return (
                    <div className='react-confirm-alert-body'>
                      <p className="text-danger cant_delivery_here">Sorry, We can't delivery here, Please choose other location.</p>
					            <div className="react-confirm-alert-button-group">
                      <button onClick={onClose}>Okay</button>

                      </div>
                    </div>
                  );
                }
              });
            }
        }).catch(function (error) {

        });
    }

    searchByLocationName = (searchTerm) => {
        var keyword = searchTerm.target.value;
        if(keyword.length >= 3){
            const results = this.state.customerSaveLocationList.filter(location =>
                location.location_name.toLowerCase().includes(keyword.toLowerCase())
            );
            this.setState({
                customerSaveLocationList: results,
                //customerSaveLocationListTemp: this.state.customerSaveLocationList
            })

            this.openSaveAddressPopup(searchTerm)
        } else if(keyword.length == 0){
            this.setState({
                customerSaveLocationList: this.state.customerSaveLocationListTemp,
                //customerSaveLocationListTemp: this.state.customerSaveLocationListTemp
            })

            this.openSaveAddressPopup(searchTerm)
        }
        console.log(keyword.length)
    };

    openSaveAddressPopup = (event) => {

        event.preventDefault();
        confirmAlert({

            customUI: ({ onClose }) => {

            return (
                <div className='react-confirm-alert-body' style={{width:"50%","margin": "0px auto"}}>
                    <div style={{width:"100%",marginBottom:"20px"}}>
                        <input  type='text' placeholder='Search by location name' className="form-control" style={{width:"100%", "marginTop": "20px"}}
                        onInput={(e) => this.searchByLocationName(e)}
                        />
                    </div>
                    {
                        this.state.customerSaveLocationList.length !== 0 ?
                            <div className="row" style={{width:"100%", maxHeight: '600px', margin:"20px", overflowY: 'auto'}}>

                                {
                                    this.state.customerSaveLocationList.map( (addressData,addressKey) => {
                                        return(
                                            <div key={addressKey} style={{ width:"100%" }} >
                                                <div>
                                                    <h5><b>Location Name: {addressData.location_name}</b></h5>
                                                    <p><b>Address:</b>{addressData.address}</p>
                                                    <button className="btn_1" onClick={e => this.addLocationFromSaveList(e,addressData)}>ADD</button>

                                                </div>
                                                <hr/>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        : <div className="row" style={{width:"100%"}}><p><b>No, Address found.</b></p></div>
                    }

                    <div className="react-confirm-alert-button-group schedule_popup_close_btn">
                        <button className="schedule_popup_close_btn" onClick={onClose} ref={input => this.schedulePopupCloseBtn = input}>Cancel</button>

                    </div>
                </div>
            );
            }
        });
    }

    render(){
        const locationData = this.state.locationHistory;
        const cartProducts = this.state.cart_products;
        const addon_products = this.state.addon_products;
        const isCartLoading =  this.state.isCartLoading;
        const selectedCartData =  this.state.selectedCartData;

        var list = [];
        for (var x = 1; x <= 500; x += 1) {
            list.push(x);
        }

        return(

                <div className="sticky_cls_tp">
                    { isCartLoading == false ?
                    <div className="theiaStickySidebar">
                        <div id="cart_box">
                            <strong className="text-danger">{ this.state.error_message }</strong>
                            {
                                Object.keys(cartProducts).length !== 0 ?
                                    !this.state.error_message ? locationData.pathname != "/order_step_one" ?

                                    <Link className="btn_full" onClick={this.orderNowBtn}>Order now</Link>
                                    :
                                    <input type="button" className="btn_full deliver_here_btn" value="Order now" onClick={this.order_palce}></input>
                                    : <a className="btn_full mt-3 mb-3 disabled-btn">Order now</a>
                                    : null
                            }
                            <h3 style={{"marginTop":"20px"}}>Your order <i className="icon_cart_alt float-right"></i></h3>
                            <table className="table table_summary">
                                <tbody>
                                    {
                                        Object.keys(cartProducts).length !== 0 ?
                                            cartProducts.map( (weightData,weightKey) => {

                                                return(

                                                    <tr key={weightKey}>
                                                        <td className="cart_action_td">
                                                            <a href="" className="remove_item" data-hfgkkeirn4df={weightData.product_code} data-jjdiejereixw={weightData.cart_table_id} onClick={this.removeItem} >
                                                                <i className="icon-cancel-circled"></i>
                                                            </a>
                                                        </td>
                                                        <td className="cart_title_td">
                                                            <strong className="cart_product_title">{weightData.product_quantity}x</strong>
                                                            {weightData.product_name}
                                                            <br/>
                                                            <span className="font-weight-light cart_weight_name">Weight: {weightData.weight}</span>

                                                        </td>
                                                        <td className="select_pckr" style={{"width":"29%"}}>
                                                            <div className="qty_container">
                                                                <a href="#" style={{"float":"left","marginTop":"11px"}} className="remove_item" data-qtjdrjnvrnkd={weightData.product_quantity} data-jjdiejereixw={weightData.cart_table_id} onClick={this.qtyPlus}>
                                                                    <i className="icon-plus-circled"></i>
                                                                </a>
                                                                <select className="form-control" style={{"width":"70px","float":"left","textAlign":"center"}} onChange={this.qtyChange} data-qtjdrjnvrnkd={weightData.product_quantity} data-jjdiejereixw={weightData.cart_table_id}>
                                                                {list.map(item => {
                                                                    return (<option value={item} key={item} selected={weightData.product_quantity == item ? "selected" : ""}>{item}</option>)
                                                                })}
                                                                </select>
                                                                <a style={{"float":"left","marginTop":"11px"}} href="#" className="remove_item" data-qtjdrjnvrnkd={weightData.product_quantity} data-jjdiejereixw={weightData.cart_table_id} onClick={this.qtyMinus}>
                                                                    <i className="icon-minus-circled"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.price_show == 'yes' &&
                                                            <td className="cart_price_td">
                                                                <strong className="float-right">SAR {weightData.product_fixed_price}</strong>
                                                            </td>
                                                        }

                                                    </tr>

                                                )
                                            }) : <div className="text-center">
                                                    <i className="icon_cart_alt empty_cart"></i><br /><br />
                                                    <span className="empty_cart_text">NO ITEMS IN CART</span>
                                                </div>
                                    }
                                </tbody>
                            </table>
                            { Object.keys(addon_products).length !== 0 ?
                                <>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            <label>Addon</label>
                                        </div>
                                    </div>
                                    <hr/>


                                    <table className="table table_summary">
                                        <tbody>
                                            {
                                                addon_products.map( (addonData,addonKey) => {

                                                    return(

                                                        <tr key={addonKey}>
                                                            <td className="cart_action_td">
                                                                <a href="" className="remove_item" data-hfgkkeirn4df={addonData.product_code} data-jjdiejereixw={addonData.cart_table_id} onClick={this.removeItem}>
                                                                    <i className="icon-cancel-circled"></i>
                                                                </a>
                                                            </td>
                                                            <td className="cart_title_td">
                                                                <strong  className="cart_product_title">{addonData.product_quantity}x</strong>
                                                                {addonData.product_name}
                                                                <br/>
                                                                <span className="font-weight-light cart_weight_name">Addon: {addonData.weight}</span>

                                                            </td>
                                                            <td style={{"width":"29%"}}>
                                                            <div className="qty_container">
                                                                <a style={{"float":"left","marginTop":"11px"}} href="#" className="remove_item" data-qtjdrjnvrnkd={addonData.product_quantity} data-jjdiejereixw={addonData.cart_table_id} onClick={this.qtyPlus}>
                                                                    <i className="icon-plus-circled"></i>
                                                                </a>
                                                                <select className="form-control" style={{"width":"70px","float":"left","textAlign":"center"}} onChange={this.qtyChange} data-qtjdrjnvrnkd={addonData.product_quantity} data-jjdiejereixw={addonData.cart_table_id}>
                                                                {list.map(item => {
                                                                    return (<option value={item} key={item} selected={addonData.product_quantity == item ? "selected" : ""}>{item}</option>)
                                                                })}
                                                                </select>
                                                                <a style={{"float":"left","marginTop":"11px"}} href="#" className="remove_item" data-qtjdrjnvrnkd={addonData.product_quantity} data-jjdiejereixw={addonData.cart_table_id} onClick={this.qtyMinus}>
                                                                    <i className="icon-minus-circled"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                        {
                                                            this.state.price_show == 'yes' &&
                                                            <td className="cart_price_td">
                                                                <strong className="float-right">SAR {addonData.product_fixed_price}</strong>
                                                            </td>
                                                        }
                                                    </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </>
                            : null}
                            <hr/>

                            {
                                Object.keys(cartProducts).length !== 0 ?
                                <>
                                    <div className="row" id="options_2">

                                        {
                                            this.state.is_now_on == 'yes' && this.state.is_schedule_order != 'yes' ?
                                                <div className="col-xl-6 col-md-12 col-sm-12 col-6 iradio_square-grey_con">
                                                    <label>
                                                    <Radio
                                                    name="delivery_type"
                                                    radioClass="iradio_square-purple"
                                                    label="Now"
                                                    value="now"
                                                    onClick={this.deliveryType}
                                                    checked={this.state.delivery_type === "now"}
                                                    />
                                                    </label>

                                                </div> : null
                                        }
                                        {
                                            this.state.is_schedule_on == 'yes' ?
                                                <div  className={(this.state.is_now_on == 'yes' && this.state.is_schedule_order != 'yes' ? 'col-xl-6 col-md-12 iradio_square-grey_con' : 'col-xl-12 col-md-12 col-sm-12 col-12 iradio_square-grey_con')}>
                                                    <label>
                                                    <Radio
                                                    name="delivery_type"
                                                    radioClass="iradio_square-purple"
                                                    label="Schedule"
                                                    value="schedule"
                                                    onClick={this.deliveryType}
                                                    checked={this.state.delivery_type == "schedule"}
                                                    />

                                                    </label> {this.state.preparation_text ? "( "+ this.state.preparation_text + " )" :null }
                                                </div> : null
                                        }

                                    </div>

                                    {
                                        // this.state.from ?
                                        // <div className="row">

                                        //     <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                        //     <hr/>
                                        //     Delivery Date: <strong>{this.state.from} { this.state.to && "to " + this.state.to}</strong>
                                        //     </div>
                                        //     <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                        //     Delivery Time: <strong>{this.state.schedule_slot}</strong>

                                        //     </div>
                                        // </div>
                                        // :
                                        // null
                                    }
                                    <hr/>

                                    {
                                        this.state.customerLocationList.location &&

                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                                <div className="mt-1">
                                                <span><b>Location:</b> {this.state.customerLocationList.location} </span>
                                                <br></br>
                                                {
                                                this.state.customerLocationList.location_name &&
                                                    <span><b>Location Name:</b> {this.state.customerLocationList.location_name}</span>
                                                }
                                                {
                                                    this.state.allow_address == 'yes' &&
                                                    <p>
                                                    <br></br>
                                                    <a onClick={e => this.removeAddress(e,this.state.customerLocationList.id,this.state.customerLocationList.customer_lat,this.state.customerLocationList.customer_lng) } href="#">Remove <i className="icon-minus-circled"></i></a>
                                                    <hr />
                                                    </p>
                                                }

                                                </div>
                                            </div>
                                        </div>
                                    }
                                     {
                                        this.state.allow_address == 'yes' && !this.state.customerLocationList.location ?
                                            <div className="row">
                                                <div className="col-xl-5 col-md-6 col-sm-6 col-7 mt-3">
                                                <Link to="/add_location" style={{"backgroundColor":"#5a368d","color":"#fff","padding":"5px","borderRadius":"5px","minWidth": "159px","display": "inline-block","textAlign": "center"}}>Add Delivery Location <i className="icon-plus-circled"></i></Link>
                                                </div>
                                                <div className="col-xl-4 col-md-4 col-sm-4 col-4 mt-3">
                                                {
                                                    this.state.customerSaveLocationList.length != 0 &&
                                                    <a onClick={this.openSaveAddressPopup} href="#" style={{"backgroundColor":"#5a368d","color":"#fff","padding":"5px","borderRadius":"5px"}}>Saved Location</a>
                                                }
                                                <hr />
                                            </div>
                                            </div> : null
                                    }
                                     <hr />
                                    {
                                        this.state.payment_method  ?
                                        <div className="row">
                                            <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                                            Payment Method: <strong>{this.state.payment_method_label}</strong>
                                            {
                                                this.state.selectedCartData.card_number &&
                                                <div className="credit-card-show-last"> { this.state.selectedCartData.card_number.substr(this.state.selectedCartData.card_number.length - 4) }  </div>

                                            }
                                            {
                                                this.state.selectedCreditDate &&  <strong> ( { this.state.selectedCreditDate } ) </strong>
                                            }
                                            <hr />
                                            </div>

                                        </div>

                                        :

                                        null
                                    }

                                    <table className="table table_summary">
                                        {
                                            this.state.price_show == 'yes' &&
                                            <tbody>
                                            <tr>
                                                <td>
                                                Subtotal <span className="float-right">SAR {this.state.subtotal}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                Delivery fee <span className="float-right">SAR {this.state.delivery_cost}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="total">
                                                TOTAL <span className="float-right">{this.state.final_amount}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        }
                                    </table>
                                    <hr />
                                    <strong className="text-danger">{ this.state.error_message }</strong>
                                    {
                                        !this.state.error_message ? locationData.pathname != "/order_step_one" ?

                                        <Link className="btn_full" onClick={this.orderNowBtn}>Order now</Link>
                                        :
                                        <input type="button" className="btn_full deliver_here_btn" value="Order now" onClick={this.order_palce}></input>
                                        : <a className="btn_full mt-3 mb-3 disabled-btn">Order now</a>
                                    }

                                </>
                            : null
                            }
                        </div>
                    </div>
                    :
                    <ViewCartSkeleton key={this.state.unique_id} location={this.props.location}/>
                    }
                </div>

        )
    }

}


export default withRouter(CartSummeryWidegt);
