import React, { Component } from 'react';
import axios from "axios";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Autocomplete from "react-google-autocomplete";

import 'icheck/skins/all.css';
import {Checkbox} from 'react-icheck';

import { SHOP_DELIVERED_OR_NOT_API_URL, SAVE_ADDRESS, MAP_GOOGLE_KEY } from '../Comman/Constant';

class AddLocation extends React.Component{

    constructor(){
        super();

        this.state = {
            customerAddress : "",
            save_address : 'no',
            language_id: 1,
            device_id: localStorage.getItem('device_id'),
            mapRenderKey: new Date().getTime()
          };
    }


    componentDidMount(){

		navigator.geolocation.getCurrentPosition(

			function(position) {

			const { latLng } = position.coords;
			const currentPosition = position;
			const currentLatitude = position.coords.latitude;
			const currentLongitude = position.coords.longitude;

			const google = window.google
			let geocoder = new google.maps.Geocoder();
			let customerAddress = '';

			geocoder.geocode({
				latLng: { lat: currentLatitude, lng: currentLongitude}
			}, function(responses) {

				if (responses && responses.length > 0) {
					customerAddress = responses[0].formatted_address;
					this.setState({
					customerLat: currentLatitude,
					customerLng: currentLongitude,
					customerLatReset: currentLatitude,
					customerLngReset: currentLongitude,
					customerAddressReset: customerAddress,
					customerAddress: customerAddress,
					});
				}
			}.bind(this));

			}.bind(this),
			function(error) {
			const currentError = error.message;
			this.setState({
				currentError: currentError,
				currentErrorCode: error.code,
			});
			}.bind(this),
			{
			maximumAge:10000, timeout:5000, enableHighAccuracy: true
			}

		);
    }

    setLocationNote = (event) => {
      this.setState({[event.target.name]: event.target.value});
    }

    cancelAddress = () => {
      this.props.history.goBack();
    }

	handleSubmit = (e) => {
		e.preventDefault();
    this.state.save_address = 'yes';
		var that = this;

		if(1){
			axios.post(SHOP_DELIVERED_OR_NOT_API_URL, that.state ,{
			headers: {
				'Authorization': localStorage.getItem('device_id')
				}
			})
			.then((response) => {
				if(response.data.shop_exits == true){
					this.props.history.goBack();
				} else {
				confirmAlert({

					customUI: ({ onClose }) => {
					return (
						<div className='react-confirm-alert-body'>
						<p className="text-danger cant_delivery_here">{response.data.error_message}</p>
									<div className="react-confirm-alert-button-group">
						<button onClick={onClose}>Okay</button>

						</div>
						</div>
					);
					}
				});
				}
			}).catch(function (error) {
				if(error.response.status==401){
						that.props.history.push('/sign-in');
				}
			});
		} else {
			this.props.history.goBack();
		}


	}

	handleExtraField = (e) => {

		this.setState({
			[e.target.name]: e.target.value
		})
	}


    deliver_here = () => {
      var that = this;
        axios.post(SAVE_ADDRESS, {
            language_id: 1,
            cust_lat: this.state.customerLat,
            cust_lng: this.state.customerLng,
            cust_add: this.state.customerAddress,
            address_note: this.state.address_note,
            device_id: localStorage.getItem('device_id'),
            save_address: this.state.save_address,
            save_address_value: this.state.save_address_value
        },{
                headers: {
                'Authorization': localStorage.getItem('device_id')
                }
            })
        .then((response) => {
            if(response.data.shop_exits == true){

                this.props.history.goBack();

            } else {
              confirmAlert({

                customUI: ({ onClose }) => {
                  return (
                    <div className='react-confirm-alert-body'>
                      <p className="text-danger cant_delivery_here">{response.data.error_message}</p>
					            <div className="react-confirm-alert-button-group">
                      <button onClick={onClose}>Okay</button>

                      </div>
                    </div>
                  );
                }
              });
            }
        }).catch(function (error) {
              if(error.response.status==401){
                    that.props.history.push('/sign-in');
              }
        });
    }

    onMarkerDragEnd = (coord) => {
        const { latLng } = coord;

        const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const google = window.google
        let geocoder = new google.maps.Geocoder();
        let customerAddress = '';

        geocoder.geocode({
          latLng: latLng
        }, function(responses) {
          if (responses && responses.length > 0) {
              customerAddress = responses[0].formatted_address;
              this.setState({
                customerLat: currentLatitude,
                customerLng: currentLongitude,
                customerAddress: customerAddress,
              });
          }
        }.bind(this));
    };

    mapClicked = (mapProps, map, clickEvent) => {
     const latLng  = clickEvent.latLng;

      const currentLatitude = latLng.lat();
        const currentLongitude = latLng.lng();
        const google = window.google
        let geocoder = new google.maps.Geocoder();
        let customerAddress = '';

        geocoder.geocode({
          latLng: latLng
        }, function(responses) {
          if (responses && responses.length > 0) {
              customerAddress = responses[0].formatted_address;
              this.setState({
                customerLat: currentLatitude,
                customerLng: currentLongitude,
                customerAddress: customerAddress,
              });
          }
        }.bind(this));
    }

    saveAddress = (event) => {
      const target = event.currentTarget;
      var value = target.value;
      if(value == 'no'){
        this.setState({
          save_address : 'yes'
        });
      } else {
        this.setState({
          save_address : 'no'
        });
      }
    }

    saveAddressValue = (event) => {
      this.setState({[event.target.name]: event.target.value});
    }

    onPlaceSelected = (place) => {
        let customerAddress = place.formatted_address;
        const currentLatitude = place.geometry.location.lat();
        const currentLongitude = place.geometry.location.lng();

        this.setState({
            customerLat: currentLatitude,
            customerLng: currentLongitude,
            customerAddress: customerAddress,
            mapRenderKey: new Date().getTime()
        });
    }

    render(){
        const mapStyles = {
            width: '100%',
            height: '500px',
          };

          return(
            <>
            {
              this.state.customerLat &&
              <div id="customer_location_map">
                  <div className='autocomplete_div'>
                      <Autocomplete
                          apiKey={MAP_GOOGLE_KEY}
                          onPlaceSelected={(place) => this.onPlaceSelected(place)}
                          options={{
                              types: ["geocode", "establishment"],
                          }}
                      />
                  </div>

                  <Map
                  key={this.state.mapRenderKey}
                  google={this.props.google}
                  zoom={18}
                  style={mapStyles}
                  initialCenter={{ lat: this.state.customerLat, lng: this.state.customerLng}}
                  onClick={this.mapClicked}
                  >
                    <Marker
                    position={{ lat: this.state.customerLat, lng: this.state.customerLng}}
                    draggable={true}
                    onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                    />
                  </Map>
            </div>
            }

            <div className="customerLocationInfo container margin_60_35">
              {
                this.state.currentErrorCode == 1 &&
                <div className="row">
                    <div className="col-lg-6 choosenLocation">
                        <span className="font-weight-bold">Error:</span>
                        <span className="customerSelectedAddress">Please allow location permission</span>
                    </div>
                </div>
              }
              {
                this.state.customerLat &&
                <div className="row">
                    <div className="col-lg-6 choosenLocation">
                        <span className="font-weight-bold">Deliver to:</span>
                        <span className="customerSelectedAddress">{this.state.customerAddress}</span>
                    </div>
                    <div className="col-lg-6">
                        <div className="col-md-12">
							<form onSubmit={this.handleSubmit}>
                            {/* <label>Add delivery instructions (optional)</label>
                            <textarea className="form-control" style={{height:'50px'}} placeholder="" name="address_note" id="notes" onChange={this.setLocationNote}></textarea>
                             <div className="row mt-3">
                                <div className="col-lg-4">
                                    <Checkbox
                                      checkboxClass="icheckbox_square-purple"
                                      label="Save Address"
                                      onClick={this.saveAddress}
                                      value={this.state.save_address}
                                    />
                                </div>
                               <div className="col-lg-8">
                                  {
                                    this.state.save_address == 'yes' &&
                                    <input type="text" name="save_address_value" className="form-control" onChange={this.saveAddressValue} ></input>
                                  }

                               </div>
                            </div> */}
							{
								true &&
								<div className='extra_field_container'>
									<div className="row">
										<div className="col-lg-6">
											<div class="form-group">
												<label>Location Name</label>
												<input type="text" name="location_name" className="form-control" onChange={this.handleExtraField} required></input>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>Location Type</label>
												<input type="text" name="location_type" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-12">
											<div class="form-group">
												<label>Location comments</label>
												<input type="text" name="location_comments" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
										{/* <div className="col-lg-6">
											<div class="form-group">
												<label>Location Image</label>
												<input type="text" name="location_image" className="form-control" onChange={this.handleExtraField} required></input>
											</div>
										</div>               */}
									</div>
									<div className="row">
										<div className="col-lg-6">
											<div class="form-group">
												<label>Customer contact info</label>
												<input type="text" name="customer_contact_info" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>Driver contact info</label>
												<input type="text" name="driver_contact_info" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
									</div>
									<div className="row">

										<div className="col-lg-6">
											<div class="form-group">
												<label>Supplier contact info</label>
												<input type="text" name="supplied_contact_info" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
										<div className="col-lg-6">
											<div class="form-group">
												<label>Complex name</label>
												<input type="text" name="complex_name" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
									</div>
									<div className="row">

										<div className="col-lg-12">
											<div class="form-group">
												<label>Gate No</label>
												<input type="text" name="gate_no" className="form-control" onChange={this.handleExtraField} ></input>
											</div>
										</div>
									</div>
								</div>
							}


                            <div className="row">
                               <div className="col-lg-6"><input type="submit" className="btn_full deliver_here_btn" value="Add Location" ></input></div>
							   {/* onClick={this.deliver_here} */}
                               <div className="col-lg-6"><input type="button" className="btn_full deliver_here_btn" value="Cancel" onClick={this.cancelAddress}></input></div>
                            </div>

                             </form>
                        </div>
                    </div>

                </div>
              }

            </div>
        </>
          );
    }

}

export default GoogleApiWrapper({
  apiKey: MAP_GOOGLE_KEY
})(AddLocation);
